<script setup lang="ts">
const isAuthenticated = useAuthState();
const isDesktop = useIsDesktop();
const route = useRoute();
const showMobileNavigation = computed(() => {
  return !isDesktop.value && isAuthenticated.value && route.meta.showMobileNavigation;
});
</script>

<template>
  <div id="page_layout" class="tw-layout layout--default">
    <div id="page_header" class="tw-layout--page-header-wrapper">
      <tw-layout-page-header class="tw-layout-page-header" />
    </div>
    <div id="page_header_border"></div>

    <div class="tw-layout--page-content-wrapper">
      <main id="page_main" class="tw-page-content">
        <slot />
      </main>

      <aside id="page_sidebar" class="tw-page-sidebar" />
    </div>
    <div id="page_footer" class="tw-layout--page-footer-wrapper">
      <tw-layout-page-footer />
    </div>
    <template v-if="showMobileNavigation">
      <div id="tw-mobile-navigation">
        <tw-layout-page-mobile-navigation />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.tw-layout {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-content: stretch;

  .tw-layout--page-content-wrapper {
    flex: 1;
    align-self: stretch;
    justify-self: stretch;
  }

  #page_header,
  #page_main {
    > :deep(*:not(.tw-layout--disable-breakpoints)) {
      max-width: 1140px;
      margin: 0 auto;
    }
  }

  #page_header_border {
    height: 2px;
    width: 100%;
    background-color: #00000033;
    filter: blur(1.5px);
  }

  :deep(.tw-layout--enable-breakpoints) {
    max-width: 1140px;
    margin: 0 auto;
    padding: 1em;
  }
  #tw-mobile-navigation {
    bottom: 0;
    z-index: 10;
    position: sticky;
  }
}

.tw-loading-placeholder {
  .tw-loading-indicator {
    display: block;
    width: 50vw;
    max-width: 256px;
    margin: 20vh auto 0;
  }
}
</style>
